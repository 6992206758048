import React, { useEffect, useState } from "react";
import "./Selector.css"; // Import CSS file
import { Icon } from "@chakra-ui/react";
import { MdClear } from "react-icons/md";

export function Selector({
  placeholder,
  listItems,
  handleSelectionChange,
  handleSelectionClear,
  selectedValue,
  isLoading,
  setSearch,
}: {
  placeholder: string;
  listItems: string[];
  selectedValue: string;
  handleSelectionChange: (update: any) => void;
  handleSelectionClear: () => void;
  unitClassName?: string;
  isLoading?: boolean;
  setSearch?: (search: string) => void;
}) {
  const [inputValue, setInputValue] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const filteredItems = listItems.filter((item) => item.toLowerCase().includes(inputValue.toLowerCase()));
  const sortedItems = filteredItems.sort((a, b) => {
    const indexA = a.toLowerCase().indexOf(inputValue.toLowerCase());
    const indexB = b.toLowerCase().indexOf(inputValue.toLowerCase());
    return indexA - indexB || a.localeCompare(b);
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch ? setSearch(e.target.value) : setInputValue(e.target.value);
    if (e.target.value.length !== 0) setIsSearching(true);
    if (e.target.value.length === 0) handleSelectionChange("");
  };

  const handleFocus = () => {
    setIsSearching(true);
    setIsFocus(true);
  };

  const handleBlur = () => {
    setTimeout(() => setIsSearching(false), 5);
    setIsFocus(false);
  };

  const handleClick = (item: string) => {
    handleSelectionChange(item);
    setInputValue(item);
    setIsSearching(false);
  };

  const handleClear = () => {
    setInputValue("");
    handleSelectionClear();
  };

  useEffect(() => {
    setInputValue(selectedValue);
  }, [selectedValue]);

  return (
    <div className="selectorContainer">
      <span className={isFocus ? "inputClearSpanFocus" : "inputClearSpan"}>
        <input
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={isFocus ? "inputFocus" : "input"}
        />
        <div className={isFocus ? "inputClearFocus" : "inputClear"} onClick={handleClear}>
          {inputValue.length ? (
            <Icon fontSize="xl">
              <MdClear />
            </Icon>
          ) : (
            ""
          )}
        </div>
      </span>
      {isSearching &&
        !isLoading &&
        (filteredItems.length > 0 ? (
          <div
            className="menuList" // Add className for styling
          >
            {sortedItems.map((item, i) => (
              <div
                key={item}
                onClick={() => handleClick(item)}
                className={item === selectedValue ? "menuItemSelected" : "menuItem"} // Add className for styling
              >
                {item}
              </div>
            ))}
          </div>
        ) : (
          <div
            className="menuList" // Add className for styling
          >
            <div
              key={"none"}
              className={"menuItem"} // Add className for styling
            >
              No result found
            </div>
          </div>
        ))}
      {isLoading && (
        <div
          className="menuList" // Add className for styling
        >
          <div
            key={"none"}
            className={"menuItem"} // Add className for styling
          >
            Searching...
          </div>
        </div>
      )}
    </div>
  );
}

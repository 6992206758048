import React, { useEffect, useState } from "react";
import "./Selector.css"; // Import CSS file
import { JobType, SearchState } from "../../types";
import api from "../../api";

interface ProductsResponse {
  products?: any[];
  error?: string;
}

export function SearchSelector({
  setSearchState,
  searchState,
  clearTrigger,
}: {
  searchState: SearchState;
  setSearchState: (newState: Partial<SearchState>) => void;
  clearTrigger: boolean;
}) {
  const [nameSearch, setNameSearch] = useState("");
  const [selected, setSelected] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [nameSearchResults, setNameSearchResults] = useState<any[]>([]);
  const [nameSearchLoading, setNameSearchLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [timeout, setTimeoutRef] = useState<NodeJS.Timeout | null>(null);

  const fetchProductsByName = async () => {
    const payload = {
      jobType: JobType.NAME_SEARCH,
      params: {
        name: nameSearch,
      },
    };
    try {
      const res = await api.post("/job/initiate", payload);
      setNameSearchResults(res.data.products);
      setNameSearchLoading(false);
    } catch (e) {
      console.error("Error fetching products", e);
    }
  };

  const value = searchState.barcode ? `Barcode : ${searchState.barcode}` : nameSearch;

  useEffect(() => {
    if (selected) {
      setSelected(false);
      return;
    }
    if (timeout) clearTimeout(timeout);
    if (nameSearch.length === 0) {
      setNameSearchLoading(false);
      return;
    }
    const newTimeout = setTimeout(() => fetchProductsByName(), 2000);
    setNameSearchLoading(true);
    setTimeoutRef(newTimeout);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [nameSearch]);

  useEffect(() => {
    setNameSearch("");
  }, [clearTrigger]);

  const handleFocus = () => {
    setIsOpen(true);
    setIsFocus(true);
  };

  const handleBlur = () => {
    setTimeout(() => setIsOpen(false), 5);
    setIsFocus(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameSearch(e.target.value);
  };

  const handleClear = () => {
    setNameSearch("");
    setSearchState({ barcode: null });
  };

  const handleClick = (item: any) => {
    const { category, subCategory, brand } = item;
    let update: any = {
      category,
      subCategories: { [subCategory]: true },
      brands: { [brand]: true },
    };
    setSearchState(update);
    setNameSearch("");
    setSelected(true);
    setIsOpen(false);
  };

  return (
    <div className="selectorContainer">
      <span className={isFocus ? "inputClearSpanFocus" : "inputClearSpan"}>
        <input
          type="text"
          placeholder={"Search by title"}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleInputChange}
          className={nameSearch.length ? "input" : "inputEmpty"}
        />
        <div className={isFocus ? "inputClearFocus" : "inputClear"} onClick={handleClear}>
          {nameSearch.length ? "X" : ""}
        </div>
      </span>
      {!nameSearchLoading &&
        isOpen &&
        (nameSearchResults.length > 0 && nameSearch.length > 0 ? (
          <div className="menuList">
            {nameSearchResults.map((item, i) => (
              <div key={item} onClick={() => handleClick(item)} className={"menuItem"}>
                <div className="nameSearchItem">
                  <p> {item.title}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="menuList">
            <div key={"none"} className={"menuItem"}>
              No result found
            </div>
          </div>
        ))}
      {nameSearchLoading && isOpen && (
        <div className="menuList">
          <div key={"none"} className={"menuItem"}>
            Searching...
          </div>
        </div>
      )}
    </div>
  );
}

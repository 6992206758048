import axios from "axios";
import { BACKEND_URL } from "./resources";
import { createStandaloneToast } from "@chakra-ui/react";
import Cookies from "js-cookie";

const { toast } = createStandaloneToast();

const api = axios.create({
  baseURL: BACKEND_URL,
});

// Handle errors and format response data
api.interceptors.response.use(
  (response) => {
    return response.data;
  }, // If successful, return response
  (error) => {
    const message = error.response?.data?.error || "Something went wrong";
    toast({
      title: message,
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top",
      variant: "subtle",
    });
    return Promise.reject(error);
  }
);

// Apply authentication token for requests
api.interceptors.request.use(
  (config) => {
    const token = Cookies.get("_auth");
    if (token) config.headers.Authorization = `Bearer ${token}`; // Append Authorization header
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;

import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { SelectionContextState } from "../types";
import { initialSelectionState } from "../resources";

export const SelectionContext = createContext<SelectionContextState | undefined>(undefined);

export function SelectionProvider({ children }: { children: ReactNode }) {
  const [selectionState, setSelectionState] = useState(() => {
    const selectState = localStorage.getItem("selectState");
    return selectState ? JSON.parse(selectState) : initialSelectionState;
  });

  useEffect(() => {
    localStorage.setItem("selectState", JSON.stringify(selectionState));
  }, [selectionState]);

  return (
    <SelectionContext.Provider value={{ selectionState, setSelectionState }}>{children}</SelectionContext.Provider>
  );
}

export function useSelectionContext() {
  const context = useContext(SelectionContext);
  if (context === undefined) {
    throw new Error("useCartContext must be used within a SelectionContextProvider");
  }
  return context;
}

import { Overlay2 } from "@blueprintjs/core";
import { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import "./EditNameOverlay.css";
import { createStandaloneToast } from "@chakra-ui/react";
import api from "../../api";

const { toast } = createStandaloneToast();

export function EditNameOverlay({
  name,
  templateId,
  setTemplateName,
}: {
  name: string;
  templateId: string;
  setTemplateName: (name: string) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [tempName, setTempName] = useState<string>(name);
  const [isClosed, setIsClosed] = useState(true);

  function handleOpenClick() {
    setIsOpen(true);
    setIsClosed(false);
  }

  function handleCloseClick() {
    setIsOpen(false);
    setIsClosed(true);
  }

  function handleNameChange(newName: string) {
    setTempName(newName);
    setTemplateName(newName);
  }

  async function handleSave() {
    await api.post("/account/template/edit", {
      name: tempName,
      templateId,
    });
    handleCloseClick();
    toast({
      title: "Template successfully editted!",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top",
      variant: "subtle",
    });
  }

  return (
    <>
      <span onClick={handleOpenClick} className="editNameSpan">
        <FaRegEdit /> Edit name
      </span>
      {isOpen && (
        <Overlay2 isOpen={isOpen} className={isClosed ? "" : "modalOverlay"} hasBackdrop={true}>
          <div className="modalContainer">
            <div className="modalCompleteContent">
              <h1 className="editNameHeading">Change your template name</h1>
              <input placeholder="Name" value={tempName} onChange={(e) => handleNameChange(e.target.value)} />
              <span className="buttonSpan">
                <button className="secondaryButton" onClick={handleCloseClick}>
                  Cancel
                </button>
                <button className="primaryButton" onClick={handleSave}>
                  Save
                </button>
              </span>
            </div>
          </div>
        </Overlay2>
      )}
    </>
  );
}

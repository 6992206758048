/* eslint-disable */
import "./Shop.css";
import { Icon } from "@blueprintjs/core";
import { createStandaloneToast } from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { JobType, MultipleProductInfo, RankingDetails, SearchState, SelectionState, Stores } from "../../types";
import { ProductCard } from "./ProductCard";
import CircularProgress from "@mui/material/CircularProgress";
import { Selector } from "./Selector";
import { useSelectionContext } from "../../hooks";
import { _INITIAL_RANKING_DETAILS, initialSelectionState, themeColours } from "../../resources";
import { useStateObject } from "../../hooks/useStateObject";
import { SearchSelector } from "./SearchSelector";
import { MultiSelector } from "./MultiSelector";
import { capitalize, getAlert, getBrandsCategory, getBrandsForSubCategory, getSubCategories } from "../../utils";
import Checkbox from "../../components/Checkbox";
import { MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";
import api from "../../api";

const { toast } = createStandaloneToast();

export type CategoryMapping = {
  [key: string]: { subCategories: { [key: string]: { [key: string]: { [key: string]: true } } }; unit: string };
};
export type BrandMapping = { [key: string]: string[] };

export function Shop() {
  const { selectionState, setSelectionState } = useSelectionContext();
  const [searchState, setSearchState] = useStateObject<SearchState>(selectionState["current"]);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const searchRef = useRef(searchState);
  const [products, setProducts] = useState<MultipleProductInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryMapping, setCategoryMapping] = useState<CategoryMapping>({});
  const [clearTrigger, setClearTrigger] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [placeholders, setPlaceholders] = useState(["-", "-"]);
  const [rankingDetails, setRankingDetails] = useState<RankingDetails>(_INITIAL_RANKING_DETAILS);

  const minVolumeValue = [-Infinity, null].includes(searchState.minVolume) ? "" : searchState.minVolume ?? "";
  const maxVolumeValue = [Infinity, null].includes(searchState.maxVolume) ? "" : searchState.maxVolume ?? "";
  const volumeSet = minVolumeValue || maxVolumeValue;

  const categories = Object.keys(categoryMapping);
  const subCategories = Object.keys(categoryMapping[searchState.category]?.subCategories ?? {}).filter(
    (subCat) => subCat != "blank"
  );
  const stores = Object.keys(searchState.stores).filter((key) => searchState.stores[key]);
  const validBrands = useMemo(() => {
    let brands = {};
    for (const store in searchState.stores) {
      if (searchState.stores[store]) {
        for (const subCategory in searchState.subCategories) {
          brands = { ...brands, ...(categoryMapping[searchState.category]?.subCategories[subCategory]?.[store] ?? {}) };
        }
        brands = { ...brands, ...(categoryMapping[searchState.category]?.subCategories["blank"]?.[store] ?? {}) };
      }
    }
    return Object.keys(brands);
  }, [searchState.stores, categories, subCategories]);

  const fetchProductsByCategories = async () => {
    if (!isSearchable) {
      toast({
        title: "You're missing something...",
        description: getAlert(searchState),
        status: "error",
        duration: 5000, // duration in milliseconds, after which toast disappears
        isClosable: true, // allows user to close the toast manually
        position: "top", // where the toast will appear
        variant: "subtle",
      });
      return;
    }
    setHasSearched(true);
    setIsLoading(true);
    const payload = {
      jobType: JobType.CATEGORY_RANK,
      params: {
        barcode: searchState.barcode,
        category: searchState.category,
        subCategories: Object.keys(searchState.subCategories),
        stores: searchState.stores,
        minVolume: searchState.minVolume,
        maxVolume: searchState.maxVolume,
        brands: Object.keys(searchState.brands),
      },
    };
    try {
      const res = await api.post("/job/initiate", payload);
      setProducts(res.data.products);
      setIsLoading(false);
      setRankingDetails(res.data.rankingDetails);
    } catch (e) {
      console.error("Error fetching products", e);
    }
  };

  async function fetchProductsByBarcode(barcode: string) {
    setHasSearched(true);
    setIsLoading(true);
    const barcodeParam = barcode ? barcode : searchState.barcode;
    const payload = {
      jobType: JobType.CATEGORY_RANK,
      params: {
        barcode: barcodeParam,
      },
    };
    try {
      const res = await api.post("/job/initiate", payload);
      setProducts(res.data.products);
      setIsLoading(false);
      setRankingDetails(res.data.rankingDetails);
    } catch (e) {
      console.error("Error fetching products", e);
    }
  }

  const handleMinMaxChange = (val: React.ChangeEvent<HTMLInputElement>, isMin: boolean) => {
    const value = val.target.value === "" ? null : isNaN(Number(val.target.value)) ? null : Number(val.target.value);
    setSearchState(
      isMin
        ? {
            minVolume: value,
          }
        : { maxVolume: value }
    );
  };

  function handleStoreChange(store: string) {
    const currentStores = { ...searchState.stores };
    if (currentStores[store]) delete currentStores[store];
    else currentStores[store] = true;
    const brands = getBrandsCategory(categoryMapping, searchState.category, { ...searchState, stores: currentStores });
    setSearchState({ stores: currentStores, brands });
  }

  function handleCategoryChange(category: string) {
    let currentCategory = searchState.category;
    if (currentCategory === category) {
      setSearchState({
        category: "",
        subCategories: {},
        brands: {},
      });
    } else {
      const subCategories = getSubCategories(categoryMapping, category);
      const brands = getBrandsCategory(categoryMapping, category, { ...searchState, subCategories, category });
      setSearchState({
        category,
        subCategories,
        brands,
      });
    }
  }

  function handleSubCategoryChange(subCategory: string) {
    let currentsubCategories = { ...searchState.subCategories };
    if (currentsubCategories[subCategory]) {
      delete currentsubCategories[subCategory];
    } else {
      currentsubCategories[subCategory] = true;
    }
    const brands = getBrandsForSubCategory(categoryMapping, Object.keys(currentsubCategories), searchState);
    setSearchState({ subCategories: currentsubCategories, brands });
  }

  function handleCategoryClear() {
    setSearchState({ category: "", subCategories: {}, brands: {} });
  }

  function handleSelectAllSubCategory() {
    const subCategories = getSubCategories(categoryMapping, searchState.category);
    const brands = getBrandsCategory(categoryMapping, searchState.category, searchState);
    setSearchState({
      subCategories,
      brands,
    });
  }

  function handleSubCategoryClear() {
    setSearchState({ subCategories: {}, brands: {} });
  }

  function handleSelectAllBrands() {
    const brands = getBrandsForSubCategory(categoryMapping, Object.keys(searchState.subCategories), searchState);
    setSearchState({ brands });
  }

  function handleBrandsClear() {
    setSearchState({ brands: {} });
  }

  function handleStoreClear() {
    setSearchState({ stores: {} });
  }

  function handleSelectAllStore() {
    setSearchState({ stores: initialSelectionState.current.stores });
  }

  function handleBrandChange(brand: string) {
    let currentBrands = searchState.brands;
    if (currentBrands[brand]) {
      delete currentBrands[brand];
      setSearchState({
        brands: currentBrands,
      });
    } else {
      currentBrands[brand] = true;
      setSearchState({
        brands: currentBrands,
      });
    }
  }

  function scrollToTop() {
    document.getElementById("top")?.scrollIntoView({ behavior: "smooth" });
  }

  const clearResults = () => {
    setProducts([]);
    setHasSearched(false);
    setSearchState(initialSelectionState["current"]);
    setSelectionState((prev) => ({ ...prev, current: initialSelectionState["current"] }));
    handleBrandsClear();
    handleSubCategoryClear();
    setClearTrigger((prev) => !prev);
  };

  const isSearchable = useMemo(() => {
    const isStoreSelected = Object.values(searchState.stores).some((val) => val);
    const isBarcodeSelected = !!searchState.barcode;
    const isCategorySelected = !!searchState.category;
    const isSubCategorySelcted =
      Object.keys(searchState.subCategories).length || getSubCategories(categoryMapping, searchState.category)["blank"];
    const isBrandSelected = Object.keys(searchState.brands).length;
    return isStoreSelected && (isBarcodeSelected || (isCategorySelected && isSubCategorySelcted && isBrandSelected));
  }, [searchState]);

  useEffect(() => {
    searchRef.current = searchState;
  }, [searchState]);

  /* eslint-disable */
  useEffect(() => {
    if (isSearchable) {
      fetchProductsByCategories();
    }
    return () => {
      setSelectionState((prev: SelectionState) => ({
        ...prev,
        current: { ...searchRef.current, title: "" },
      }));
    };
  }, []);
  /* eslint-enable */

  useEffect(() => {
    const fetchProductMapping = async () => {
      const payload = {
        jobType: JobType.PRODUCT_MAPPING,
        params: {},
      };
      try {
        const res = await api.post("/job/initiate", payload);
        setCategoryMapping(res.data);
        setIsPageLoading(false);
      } catch (e) {
        console.error("Error fetching products", e);
      }
    };
    setIsPageLoading(true);
    fetchProductMapping();
  }, []);

  if (isPageLoading) {
    return (
      <div className="pageContainer">
        <div className="spinnerContainer">
          <div className="spinner"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="pageContainer">
      <div className="searchContainer">
        <div id="top"></div>
        {products.length > 0 && (
          <div className="storeArrow" onClick={scrollToTop}>
            <MdOutlineKeyboardDoubleArrowUp color={themeColours.secondary} size={20} />
          </div>
        )}
        <div className="storeContentContainer">
          <span className="textContainer">
            <text className="shopText">Select your stores</text>
            <Icon icon="shop" size={12} color={themeColours.secondary} />
          </span>
          <span className="shopCheckboxContainer">
            {[Stores.WOOLWORTHS, Stores.CHECKERS].map((store) => (
              <Checkbox
                onClick={() => handleStoreChange(store)}
                active={searchState.stores[store]}
                label={capitalize(store)}
              />
            ))}
          </span>
          <span className="shopCheckboxContainer">
            {[Stores.DISCHEM, Stores.CLICKS].map((store) => (
              <Checkbox
                onClick={() => handleStoreChange(store)}
                active={searchState.stores[store]}
                label={capitalize(store)}
              />
            ))}
          </span>
        </div>
        <div className="storeContentContainer">
          <span className="textContainer">
            <text className="shopText">Describe an item</text>
            <Icon icon="barcode" color={themeColours.secondary} size={12} />
          </span>
          <div className="searchInputContainer">
            <SearchSelector setSearchState={setSearchState} clearTrigger={clearTrigger} searchState={searchState} />
            <Selector
              placeholder={searchState.category ? searchState.category : "Category"}
              listItems={categories}
              handleSelectionChange={handleCategoryChange}
              handleSelectionClear={handleCategoryClear}
              selectedValue={searchState.category}
            />
            <MultiSelector
              placeholder={"Sub-categories"}
              listItems={subCategories}
              handleSelectionChange={handleSubCategoryChange}
              handleSelectionClear={handleSubCategoryClear}
              handleSelectAll={handleSelectAllSubCategory}
              selectedValues={searchState.subCategories}
            />
            <MultiSelector
              placeholder={"Brands"}
              listItems={validBrands}
              handleSelectionChange={handleBrandChange}
              handleSelectionClear={handleBrandsClear}
              handleSelectAll={handleSelectAllBrands}
              selectedValues={searchState.brands}
            />
            <span className="volumeContainer">
              <span className="volumeContainerText">
                <span className="volumeMinMax">
                  <text>Min:</text>
                  <input
                    type="text"
                    value={minVolumeValue}
                    onChange={(val) => handleMinMaxChange(val, true)}
                    onFocus={() => setPlaceholders((prev) => ["", prev[1]])}
                    onBlur={() => setPlaceholders((prev) => ["-", prev[1]])}
                    placeholder={placeholders[0]}
                  />
                </span>
                <span className="volumeMinMax">
                  <text>Max:</text>
                  <input
                    type="text"
                    value={maxVolumeValue}
                    onChange={(val) => handleMinMaxChange(val, false)}
                    onFocus={() => setPlaceholders((prev) => [prev[0], ""])}
                    onBlur={() => setPlaceholders((prev) => [prev[0], "-"])}
                    placeholder={placeholders[1]}
                  />
                </span>
                <text>{categoryMapping[searchState.category]?.unit ?? "units"}</text>
              </span>
              <text onClick={() => setSearchState({ minVolume: -Infinity, maxVolume: Infinity })}>
                {volumeSet ? "X" : ""}
              </text>
            </span>
          </div>
        </div>
        <div className="buttonContainer">
          <button
            className={isSearchable ? "storeSearchButton" : "storeSearchButtonDisable"}
            onClick={fetchProductsByCategories}
          >
            Search
          </button>
          {isSearchable && (
            <div className="searchButtonContainer">
              <button className={"storeClearButton"} onClick={clearResults}>
                Clear
              </button>
            </div>
          )}
        </div>

        {isLoading && (
          <div className="spinnerContainer">
            <div className="spinner"></div>
          </div>
        )}
        {products.length > 0 && !isLoading && (
          <div className="storeContentContainer">
            <span className="textContainer">
              <text className="shopText">Results</text>
              <Icon icon="search" color={themeColours.secondary} size={12} />
            </span>
            <div className="shopResultList">
              {products.map((product) => (
                <ProductCard
                  product={product}
                  searchState={searchState}
                  rankingDetails={rankingDetails}
                  setSearchState={setSearchState}
                  fetchProducts={fetchProductsByBarcode}
                />
              ))}
            </div>
          </div>
        )}
        {products.length === 0 && hasSearched && !isLoading && "No results found"}
      </div>
    </div>
  );
}

import { Overlay2 } from "@blueprintjs/core";
import { useState } from "react";
import { useSelectionContext } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { LuBadgeCheck } from "react-icons/lu";
import { createStandaloneToast } from "@chakra-ui/react";
import { useCartContext } from "../../hooks";
import { v4 as uuid } from "uuid";
import { themeColours } from "../../resources";
import "./BasketCompleteOverlay.css";
import api from "../../api";

const { toast } = createStandaloneToast();

export function BasketCompleteOverlay({
  handleClearCart,
  totalSavings,
}: {
  handleClearCart: () => void;
  totalSavings: number;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [templateName, setTemplateName] = useState<string>("");
  const [isClosed, setIsClosed] = useState(true);
  const navigate = useNavigate();
  const { selectionState } = useSelectionContext();
  const { cartState } = useCartContext();

  function handleOpenClick() {
    setIsOpen(true);
    setIsClosed(false);
    const id = uuid();
    const date = new Date();
    const items: { [key: string]: {} } = {};
    for (const itemId in cartState) {
      const cartItem = cartState[itemId];
      const { id, multipleId, count, productSavings } = cartItem;
      items[cartItem.id] = { id, multipleId, count, productSavings };
    }
    api.post("/account/shop/save", { id, date, items, totalSavings });
  }

  function handleCloseClick() {
    handleClearCart();
    setIsOpen(false);
    setIsClosed(true);
    return navigate("/");
  }

  async function handleSave() {
    const saveSelectionState = { ...selectionState };
    delete saveSelectionState["current"];
    await api.post("/account/template/save", {
      name: templateName,
      config: { ...saveSelectionState },
    });
    handleCloseClick();
    toast({
      title: "Template succesfully saved !",
      status: "success",
      duration: 5000,
      isClosable: true, // allows user to close the toast manually
      position: "top", // where the toast will appear
      variant: "subtle",
    });
  }

  return (
    <>
      <button className="primaryButton" onClick={handleOpenClick}>
        Complete
      </button>
      {isOpen && (
        <Overlay2 isOpen={isOpen} className={isClosed ? "" : "modalOverlay"} hasBackdrop={true}>
          <div className="modalContainer">
            <div className="modalCompleteContent">
              <LuBadgeCheck color={themeColours.primary} size={30} />
              <h1>Shop complete !</h1>
              <h5 style={{ color: themeColours.secondary, marginTop: "4px" }}>Save this template?</h5>
              <input placeholder="Name" value={templateName} onChange={(e) => setTemplateName(e.target.value)} />
              <span className="buttonSpan">
                <button className="secondaryButton" onClick={handleCloseClick}>
                  No
                </button>
                <button className="primaryButton" onClick={handleSave}>
                  Yes
                </button>
              </span>
            </div>
          </div>
        </Overlay2>
      )}
    </>
  );
}

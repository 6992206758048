import { Overlay2 } from "@blueprintjs/core";

import "./ShopOverlay.css"
import { useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { useSelectionContext, useCartContext } from "../../hooks";
import { initialSelectionState, themeColours } from "../../resources";

export function ShopOverlay({
  isOpen,
  setIsOpen,
  handleTemplateShopClick
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleTemplateShopClick: () => void;
}) {
  const navigate = useNavigate();
  const {setCartState} = useCartContext()
  const {setSelectionState} = useSelectionContext()

  function handleNewShopClick() {
    localStorage.setItem('isShop', "T")
    setCartState({})
    setSelectionState(initialSelectionState)
    return navigate("/shop");
  }

  return (
    <>
      {isOpen && <Overlay2 isOpen={isOpen} className={!isOpen ? "" : "modalOverlay"} hasBackdrop={true}>
        <div className="modalContainer">
          <span className="closeOverlay">
            <IoMdClose color={themeColours.secondary} onClick={() => setIsOpen(false)} />
          </span>
          <div className="shopOverlay">
            <h1>How would you like to shop ?</h1>
            <div className="shopInfo">
              <div className="shopInfoOption" onClick={handleNewShopClick}>
                <h4>New -</h4>
                <p>Create a shop from scratch</p>
              </div>
              <div className="shopInfoOption" onClick={handleTemplateShopClick}>
                <h4>Template -</h4>
                <p>Populate from a template</p>
              </div>
            </div>
          </div>
        </div>
      </Overlay2>}
    </>
  );
}

import { ReactElement, useEffect, useState } from "react";
import "./Dashboard.css";
import { MdOutlineShoppingCart } from "react-icons/md";
import { VscGraphLine } from "react-icons/vsc";
import { LuShoppingBasket } from "react-icons/lu";
import { IoBookOutline } from "react-icons/io5";
import { MdWavingHand } from "react-icons/md";
import { VscFeedback } from "react-icons/vsc";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useNavigate } from "react-router-dom";
import { themeColours } from "../../resources";
import { Shop, Template } from "../../types";
import { Badge } from "@chakra-ui/react";
import { ShopOverlay } from "./ShopOverlay";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";
import { TemplateOverlay } from "./TemplateOverlay";
import api from "../../api";

const dashboardTile = (
  title: string,
  icon: ReactElement,
  onClick: () => void = () => {},
  isComingSoon: boolean = false
) => (
  <div className={`dashboardTile ${isComingSoon ? "tileComingSoon" : ""}`} onClick={onClick}>
    {isComingSoon && (
      <Badge
        colorScheme={"red"}
        // paddingLeft={"8px"}
        // paddingRight={"8px"}
        borderRadius={"16px"}
        alignContent={"center"}
        fontSize={"6px"}
        alignSelf={"flex-end"}
        marginRight={"8px"}
        // paddingBottom={"2px"}
      >
        Coming soon
      </Badge>
    )}
    {icon}
    <h5>{title}</h5>
  </div>
);

const savingsRow = (date: string, items: number, savings: number) => {
  const productValueSign = savings > 0 ? "" : "-";
  return (
    <div className="savingsRow">
      <span className="rowDate">
        {new Date(date).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })}
      </span>
      <span className="rowItems">{items}</span>
      <span className="rowSavings" style={{ color: savings > 0 ? themeColours.green : themeColours.red }}>
        {productValueSign}R{Math.abs(savings).toFixed(2)}
      </span>
    </div>
  );
};

export function Dashboard() {
  const user = useAuthUser();
  const { username } = user ?? ({} as any);
  const [shopData, setShopData] = useState<Shop[]>([]);
  const [templateData, setShowTemplateData] = useState<Template[]>([]);
  const [showShopOverlay, setShowShopOverlay] = useState<boolean>(false);
  const [showTemplateOverlay, setShowTemplateOverlay] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchShops = async function () {
      try {
        const res = await api.post("/account/shop/get");
        setShopData(res.data.shops);
        setIsLoading(false);
      } catch (e) {
        console.error("Error fetching shops", e);
      }
    };
    const fetchTemplates = async function () {
      try {
        const res = await api.post("/account/template/get");
        setShowTemplateData(res.data.templates);
      } catch (e) {
        console.error("Error fetching templates", e);
      }
    };
    setIsLoading(true);
    fetchShops();
    fetchTemplates();
  }, []);

  function handleNavigate(to: string) {
    return navigate(to);
  }

  function handleTemplateShopClick() {
    setShowShopOverlay(false);
    setShowTemplateOverlay(true);
  }

  function handleShopClick() {
    if (localStorage.getItem("isShop") === "T") return navigate("/shop");
    else setShowShopOverlay(true);
  }

  const hasRecentShops = shopData.length !== 0;
  const shopText = localStorage.getItem("isShop") === "T" ? "Continue Shop" : "Start Shop";

  if (isLoading) {
    return (
      <div className="pageContainer">
        <div className="spinnerContainer">
          <div className="spinner"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="pageContainer">
      <div className="dashboardContainer">
        <ShopOverlay
          isOpen={showShopOverlay}
          setIsOpen={setShowShopOverlay}
          handleTemplateShopClick={handleTemplateShopClick}
        />
        <TemplateOverlay isOpen={showTemplateOverlay} setIsOpen={setShowTemplateOverlay} templates={templateData} />
        <span className="welcomeSpan">
          <span className="welcomeHand">
            <h1 className="welcome">Hi {username} </h1>
            <MdWavingHand color={themeColours.primary} size={20} />
          </span>
        </span>
        <div className="tileGrid">
          {dashboardTile(shopText, <MdOutlineShoppingCart size={20} />, handleShopClick)}
          {dashboardTile("Price History", <VscGraphLine size={20} />, () => {}, true)}
          {dashboardTile("Templates", <LuShoppingBasket size={20} />, () => handleNavigate("/templates"))}
          {dashboardTile("Guide", <IoBookOutline size={20} />, () => handleNavigate("/guide"), true)}
          {dashboardTile("Feedback", <VscFeedback size={20} />, () => handleNavigate("/feedback"))}
        </div>
        <span className="welcomeSpan">
          <h1 className="welcome">Recent shops</h1>
        </span>
        {hasRecentShops ? (
          <div className="savingsGrid">
            <div className="savingsRow">
              <span className="rowDateHead" style={{ fontWeight: "bold" }}>
                Date
              </span>
              <span className="rowItemsHead" style={{ fontWeight: "bold" }}>
                Items
              </span>
              <span className="rowSavingsHead" style={{ fontWeight: "bold" }}>
                Savings
              </span>
            </div>
            {shopData.map((data) => savingsRow(data.date, data.itemsTotal, data.savingsTotal))}
          </div>
        ) : (
          <div className="emptyShop">
            <MdOutlineRemoveShoppingCart color={themeColours.tertiary} />
            <h4>Complete your first shop</h4>
          </div>
        )}
      </div>
    </div>
  );
}

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, Login, Shop, Profile, Guide, Basket, NotFound } from "./pages";
import "./App.css";
import AuthOutlet from "@auth-kit/react-router/AuthOutlet";
import { CartProvider } from "./hooks/useCartContext";
import { SelectionProvider } from "./hooks/useSelectionContext";
import { ChakraProvider } from "@chakra-ui/react";
import { customTheme } from "./resources";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { Templates } from "./pages/templates/Templates";
import { Feedback } from "./pages/feedback/Feedback";

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <SelectionProvider>
        <CartProvider>
          <BrowserRouter>
            <Routes>
              <Route element={<AuthOutlet fallbackPath="login" />}>
                <Route path="/" element={<Home />}>
                  <Route index element={<Dashboard />} />
                  <Route path="shop" element={<Shop />} />
                  <Route path="guide" element={<Guide />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="basket" element={<Basket />} />
                  <Route path="templates" element={<Templates />} />
                  <Route path="feedback" element={<Feedback/>} />
                </Route>
              </Route>
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<Login />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </CartProvider>
      </SelectionProvider>
    </ChakraProvider>
  );
}

export default App;

import { Outlet, Link, useNavigate } from "react-router-dom";
import "./Home.css";
import { Icon } from "@blueprintjs/core";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useCartContext } from "../../hooks";
import { AiOutlineHome } from "react-icons/ai";
import { LuShoppingBasket } from "react-icons/lu";
import { IoBookOutline } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { IoLogOutOutline } from "react-icons/io5";
import { CiMinimize1 } from "react-icons/ci";
import { MdOutlineShoppingCart } from "react-icons/md";
import { Badge } from "@chakra-ui/react";
import { themeColours } from "../../resources";
const logo = require("../../assets/logoNoBack.png");

export function Home() {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const signOut = useSignOut();
  const user = useAuthUser();
  const { username } = user ?? ({} as any);
  const [showNav, setShowNav] = useState(false);
  const { cartState } = useCartContext();
  const itemsInCart = Object.keys(cartState).length;
  const isShopAndInCart = localStorage.getItem("isShop") === "T" && currentPath === "/basket";
  const navBarRef = useRef<HTMLDivElement>(null);

  function handleLogOut() {
    setShowNav(false);
    signOut();
  }

  function handleOutsideClick(event: MouseEvent) {
    if (navBarRef.current && !navBarRef.current.contains(event.target as Node)) {
      setShowNav(false);
    }
  }

  useEffect(() => {
    if (showNav) document.addEventListener("mousedown", handleOutsideClick);
    else document.removeEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [showNav]);

  return (
    <div className="appContainer">
      <div className="topNavContainer">
        <Icon
          icon="menu"
          size={18}
          className="topNavContainerMenu"
          color={"#FFFFFF"}
          onClick={() => setShowNav(!showNav)}
        />
        {/* {pathLookup[currentPath]} */}
        {/* <img src={logo} width={35}></img> */}
        {isShopAndInCart ? (
          <span className="shopCartIcon">
            <Link to="/shop">
              <MdOutlineShoppingCart size={20} color={"#FFFFFF"} />
            </Link>
          </span>
        ) : (
          <span className="cartSpan">
            <LuShoppingBasket
              className="topNavContainerCart"
              size={20}
              color={"#FFFFFF"}
              onClick={() => {
                return navigate("/basket");
              }}
            />
            <Badge colorScheme="green" borderRadius={"16px"} fontSize={8} height={3}>
              <text>{itemsInCart}</text>
            </Badge>
          </span>
        )}
      </div>
      <AnimatePresence>
        {showNav && (
          <motion.div
            className="navContainer"
            initial={{ x: -100 }}
            animate={{ x: 0 }}
            exit={{ x: -300 }}
            transition={{ type: "spring", duration: 0.5 }}
            ref={navBarRef}
          >
            <div className="navLink navClose">
              <CiMinimize1 onClick={() => setShowNav(false)} size={20} color={"#FFFFFF"} />
            </div>
            <div className="navUser">
              <img src={logo} width={35}></img>
              <text>{username}</text>
            </div>
            <Link
              to=""
              className={`navLink ${currentPath === "/" ? "selected" : ""}`}
              onClick={() => setShowNav(false)}
            >
              <AiOutlineHome className="navLinkIcon" size={20} color={"#FFFFFF"} />
              <text>Home</text>
            </Link>
            <Link
              to="guide"
              className={`navLink ${currentPath === "/guide" ? "selected" : ""}`}
              onClick={() => setShowNav(false)}
            >
              <IoBookOutline className="navLinkIcon" size={20} color={"#FFFFFF"} />
              <text>Guide</text>
            </Link>
            <Link
              to="profile"
              className={`navLink ${currentPath === "/profile" ? "selected" : ""}`}
              onClick={() => setShowNav(false)}
            >
              <CgProfile className="navLinkIcon" size={20} color={"#FFFFFF"} />
              <text>Profile</text>
            </Link>
            <Link to="/login" className={"navLink"} onClick={() => handleLogOut()}>
              <IoLogOutOutline className="navLinkIcon" size={20} color={"#FFFFFF"} />
              <text>Logout</text>
            </Link>
          </motion.div>
        )}
      </AnimatePresence>
      {showNav && <div className="navBackdrop"></div>}
      <Outlet />
    </div>
  );
}

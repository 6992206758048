import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { CartContextState } from "../types";

export const CartContext = createContext<CartContextState | undefined>(undefined);

export function CartProvider ({children}: {children: ReactNode}) {
   const [cartState, setCartState] = useState(() => {
    // Load the cart state from localStorage on app start
    const storedCart = localStorage.getItem("cartState");
    return storedCart ? JSON.parse(storedCart) : {};
  });

  useEffect(() => {
    localStorage.setItem("cartState", JSON.stringify(cartState));
  }, [cartState])

  return <CartContext.Provider value={{cartState, setCartState}}>{children}</CartContext.Provider>
}


export function useCartContext() {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCartContext must be used within a CartContextProvider');
  }
  return context;
}
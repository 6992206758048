import { Overlay2 } from "@blueprintjs/core";
import "./TemplateOverlay.css";
import { IoMdClose } from "react-icons/io";
import { CartProductInfo, JobType, SearchState, SelectionState, Template, TemplateReturn } from "../../types";
import { LuShoppingBasket } from "react-icons/lu";
import { themeColours } from "../../resources";
import { useCartContext, useSelectionContext } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import api from "../../api";

export function TemplateOverlay({
  isOpen,
  setIsOpen,
  templates,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  templates: Template[];
}) {
  const { setCartState } = useCartContext();
  const { setSelectionState } = useSelectionContext();
  const [isShopping, setIsShopping] = useState<boolean>(false);
  const navigate = useNavigate();

  function handleAddProduct(product: CartProductInfo, searchState: SearchState) {
    const { multipleId, valueSavings, productSavings, isValueChoice, count } = product;
    setCartState((prev) => ({
      ...prev,
      [multipleId]: {
        ...product,
        count,
        valueSavings,
        productSavings,
        isValueChoice,
      },
    }));
    setSelectionState((prev: SelectionState) => ({
      ...prev,
      [multipleId]: { ...searchState, title: product.title },
    }));
  }

  async function handleTemplateClick(templateId: string) {
    setIsShopping(true);
    const payload = {
      jobType: JobType.TEMPLATE_SHOP,
      params: {
        templateId,
      },
    };
    try {
      const res = await api.post("/job/initiate", payload);
      const items: TemplateReturn = res.data;
      for (const itemId in items) {
        const { cartItem, searchState } = items[itemId];
        handleAddProduct(cartItem, searchState);
      }
    } catch (e) {
      console.error("Error shopping template", e);
    }
    localStorage.setItem("isShop", "T");
    setIsShopping(false);
    return navigate("/shop");
  }

  const templateRow = (id: string, name: string, createdAt: string) => (
    <div className="templateRow" onClick={() => handleTemplateClick(id)}>
      <span className="templateName">
        <LuShoppingBasket color={themeColours.tertiary} />
        <p>{name}</p>
      </span>
      <p style={{ color: themeColours.tertiary, textWrap: "nowrap" }}>
        {new Date(createdAt).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })}
      </p>
    </div>
  );

  let content = (
    <>
      <span className="closeOverlay">
        <IoMdClose color={themeColours.tertiary} onClick={() => setIsOpen(false)} />
      </span>
      {templates.length > 0 ? (
        <div className="templateSelectOverlay">
          <h1>Which template?</h1>
          <div className="templateGrid">
            {templates.map((template) => templateRow(template.id, template.name, template.createdAt))}
          </div>
        </div>
      ) : (
        <div className="templateSelectOverlay emptyTemplateOverlay">
          <h1>You don't have any</h1>
          <div className="emptyTemplate">
            <LuShoppingBasket color={themeColours.tertiary} />
            <h4>Complete a shop to save a template</h4>
          </div>
        </div>
      )}
    </>
  );

  if (isShopping) {
    content = (
      <div className="templateShoppingContainer">
        <h4>Grabbing your items...</h4> <div className="spinner spinner_small"></div>
      </div>
    );
  }

  return (
    <>
      {isOpen && (
        <Overlay2 isOpen={isOpen} className={!isOpen ? "" : "modalOverlay"} hasBackdrop={true}>
          <div className="modalContainer">{content}</div>
        </Overlay2>
      )}
    </>
  );
}

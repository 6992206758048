/* eslint-disable */
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Login.css";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { useState } from "react";
import api from "../../api";
const logo = require("../../assets/logoNoBack.png");

export function Login() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    firstName: "",
    lastName: "",
  });
  const [isGuest, setIsGuest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  const isLogin = currentPath === "/login";
  const navigate = useNavigate();
  const signIn = useSignIn();
  const isMobile = window.innerWidth < 500;

  async function handleForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsLoading(true);
    let res;
    try {
      const { username, password, firstName, lastName } = formData;
      if (isLogin) {
        res = await api.post("/account/login", {
          username: isGuest ? "TestUser" : username,
          password: isGuest ? "Pa$$word1!" : password,
        });
      } else {
        res = await api.post("/account/signup", {
          firstName,
          lastName,
          username,
          password,
        });
      }
      if (res.data.accessToken) {
        const { firstName, username, lastName, userId } = res.data;
        const isSignedIn = signIn({
          auth: { token: res.data.accessToken, type: "Bearer" },
          refresh: res.data.refreshToken,
          userState: { firstName, lastName, username, userId },
        });
        if (isSignedIn) return navigate("/");
        return;
      }
    } catch (e) {
      console.error(`Error ${isLogin ? "loggin in" : "signing up"}`, e);
    }
    setIsLoading(false);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  if (!isMobile) {
    return (
      <div className="mobileMessage">
        <h1 style={{ fontWeight: "bold", fontSize: 36 }}>Hi !</h1>
        <h1>This app was designed for mobile use</h1>
        <p>Open it on your mobile device to enjoy the experience.</p>
      </div>
    );
  }

  return (
    <div className="loginPageContainer">
      <form onSubmit={handleForm} className="formContainer">
        <img src={logo} width={200}></img>
        {isLogin ? (
          <>
            <input type="text" name="username" placeholder="Username" onChange={(e) => handleChange(e)} />
            <input type="password" name="password" placeholder="Password" onChange={(e) => handleChange(e)} />
          </>
        ) : (
          <>
            <span>
              <input type="text" name="firstName" placeholder="Name" onChange={(e) => handleChange(e)} />
              <input type="text" name="lastName" placeholder="Surname" onChange={(e) => handleChange(e)} />
            </span>
            <input type="text" name="username" placeholder="Username" onChange={(e) => handleChange(e)} />
            <input type="password" name="password" placeholder="Password" onChange={(e) => handleChange(e)} />
          </>
        )}
        {isLogin ? (
          <p>
            Don't have an account?{" "}
            <Link to="/signup" className="loginLink">
              Sign up
            </Link>
          </p>
        ) : (
          <p>
            Already a user?{" "}
            <Link to="/login" className="loginLink">
              Log in
            </Link>
          </p>
        )}
        {isLoading ? (
          <div className="spinner_white"></div>
        ) : (
          <button type="submit" className="primaryButton loginButton">
            {isLogin ? "Log in" : "Sign up"}
          </button>
        )}
      </form>
    </div>
  );
}

import { Icon, Overlay2 } from "@blueprintjs/core";
import { useState } from "react";
import "./BasketOverlay.css";
import { CartProductInfo, SelectionState } from "../../types";
import { useCartContext } from "../../hooks";
import { CloseIcon } from "@chakra-ui/icons";
import { useSelectionContext } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { filterTitle, getProductPrice, getProductVolume } from "../../components/utils";
import { imageWidths, storeIcon, themeColours } from "../../resources";
import { Badge } from "@chakra-ui/react";

export function BasketOverlay({ product }: { product: CartProductInfo }) {
  const [isOpen, setIsOpen] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [isClosed, setIsClosed] = useState(true);
  const navigate = useNavigate();
  const { cartState, setCartState } = useCartContext();
  const { selectionState, setSelectionState } = useSelectionContext();
  const [count, setCount] = useState(product.count);
  const productTitle = filterTitle(product).split(" x")[0];
  const productVolume = getProductVolume(product);
  const productPrice = getProductPrice(product, count);

  function handleItemRemove() {
    const newCartState = { ...cartState };
    const newSelectionState = { ...selectionState };
    delete newCartState[product.multipleId];
    delete newSelectionState[product.multipleId];
    setCartState(newCartState);
    setSelectionState(newSelectionState);
    handleCloseClick();
  }

  function handleCountClick(x: number) {
    const newCount = count + x <= 1 ? 1 : count + x;
    if (newCount !== product.count) setHasChanged(true);
    else setHasChanged(false);
    setCount(newCount);
  }

  function handleAlternativeClick() {
    const searchState = selectionState[product.multipleId];
    setSelectionState((prev: SelectionState) => ({
      ...prev,
      current: searchState,
    }));
    return navigate("/shop");
  }

  function handleOpenClick() {
    setIsOpen(true);
    setIsClosed(false);
  }

  function handleCloseClick() {
    setIsOpen(false);
    setIsClosed(true);
    setCount(product.count);
    setHasChanged(false);
  }

  function handleApply() {
    const newCartState = { ...cartState };
    newCartState[product.multipleId].count = count;
    setCartState(newCartState);
    handleCloseClick();
  }

  return (
    <>
      <Icon icon="edit" color={themeColours.secondary} className="editButton" onClick={handleOpenClick} />
      {isOpen && <Overlay2 isOpen={isOpen} className={isClosed ? "" : "modalOverlay"} hasBackdrop={true}>
        <div className="modalContainer">
          <div className="basketOverlayContent">
            <div className="basketOverlayContentHeader">
              <span>
                {product.isValueChoice && (
                  <Badge colorScheme="blue" fontSize={"8px"} variant="subtle" borderRadius={"16px"}>
                    VALUE
                  </Badge>
                )}
              </span>
              <CloseIcon onClick={handleCloseClick} color={themeColours.secondary} boxSize={3} />
            </div>
            <div className="basketOverlayProduct">
              <div className="basketOverlayProductLeft">
                <img src={storeIcon[product.store]} width={50} alt={"check"} />
                {productTitle.length < 30 ? (
                  <text className="cardTitle" style={{ textAlign: "right" }}>
                    {productTitle}{" "}
                    <text className="cardTitleHighlight">
                      {product.multipleCount > 1 ? `[X${product.multipleCount}]` : ""}
                    </text>
                  </text>
                ) : (
                  <text className="cardTitle" style={{textAlign: "right" }}>
                    {productTitle}{" "}
                    <text className="cardTitleHighlight">
                      {product.multipleCount > 1 ? `[X${product.multipleCount}]` : ""}
                    </text>
                  </text>
                )}
                <text className="cardBrand">{product.brand}</text>
                <text className="cardVolume">{productVolume}</text>
                <text className="cardPrice">
                  <Icon icon="tag" color={themeColours.primary} size={10} />R{productPrice}
                </text>
                <span className="cardCountContainer" style={{marginTop: '8px'}}>
                  <span className="unitIcon">
                    <Icon icon="minus" color="white" size={14} onClick={() => handleCountClick(-1)} />
                  </span>
                  <div className="cardUnitCount">{count}</div>
                  <span className="unitIcon">
                    <Icon icon="plus" color="white" size={14} onClick={() => handleCountClick(1)} />
                  </span>
                </span>
              </div>
              <div className="basketOverlayProductRight">
                <img src={product.imageUrl} alt={product.title} width={imageWidths[product.store] + 50} />
              </div>
            </div>
            <div className="basketOverlayButtons">
              <button className="secondaryButton" onClick={handleAlternativeClick}>
                Find alternative
              </button>
              {hasChanged ? (
                <button className="primaryButton basketOverlayApplyButton" onClick={handleApply}>
                  Apply
                </button>
              ) : (
                <button className="primaryButton basketOverlayRemoveButton" onClick={handleItemRemove}>
                  Remove
                </button>
              )}
            </div>
          </div>
        </div>
      </Overlay2>}
    </>
  );
}

import { Menu, MenuButton, MenuList, MenuItem, IconButton, createStandaloneToast } from "@chakra-ui/react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { EditNameOverlay } from "./EditNameOverlay";
import { ConfirmationOverlay } from "./ConfirmationOverlay";
import "./EditTooltip.css";
import { themeColours } from "../../resources";
import api from "../../api";

const { toast } = createStandaloneToast();

export default function TooltipMenu({
  name,
  templateId,
  handleTemplateDelete,
  setTemplateName,
}: {
  name: string;
  templateId: string;
  handleTemplateDelete: (id: string) => void;
  setTemplateName: (name: string) => void;
}) {
  async function handleDeleteTemplate() {
    await api.post("/account/template/delete", {
      templateId,
    });
    handleTemplateDelete(templateId);
    toast({
      title: "Template successfully deleted!",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top",
      variant: "subtle",
    });
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<FaRegEdit color={themeColours.secondary} />}
        aria-label="Edit menu"
        variant="ghost"
        sx={{
          _focus: { outline: "none", boxShadow: "none" }, // Removes focus outline
          _active: { bg: "transparent" }, // Prevents background change on click
          _hover: { bg: "transparent" }, // Prevents background change on hover
          minWidth: 0,
          pointerEvents: "auto", // Ensure proper interaction
        }}
      />
      <MenuList
        sx={{
          fontSize: "14px", // Change font size
          color: themeColours.primary, // Change text color
          borderRadius: "8px", // Add border radius
        }}
      >
        <MenuItem sx={{ fontWeight: "semibold", _focus: { backgroundColor: themeColours.background } }}>
          <EditNameOverlay name={name} templateId={templateId} setTemplateName={setTemplateName} />
        </MenuItem>
        <MenuItem sx={{ fontWeight: "semibold", _focus: { backgroundColor: themeColours.background } }}>
          <ConfirmationOverlay
            element={
              <span className="deleteItem">
                <RiDeleteBin5Line /> Delete
              </span>
            }
            text={"Are you sure ?"}
            onYes={handleDeleteTemplate}
            toastMsg="Template succesfully deleted !"
          />
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

import { useState } from "react";
import "./Feedback.css";
import api from "../../api";
import { createStandaloneToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
const feedback = require("../../assets/feedback.png");

const { toast } = createStandaloneToast();

export function Feedback() {
  const [feedbackText, setFeedbackText] = useState<string>("");
  const navigate = useNavigate();

  async function handleFeedbackSubmit() {
    await api.post("/account/feedback", {feedback: feedbackText})
    toast({
      title: "Feedback successfully submitted!",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top",
      variant: "subtle",
    });
    setFeedbackText("")
    setTimeout(() => navigate("/"), 1000)
  }
  return (
    <div className="pageContainer">
      <div className="feedbackPage">
        <img src={feedback} className="feedbackImage"></img>
        <div className="feedbackContainer">
          <div className="feedbackHeader">
            <h4>How'd it go ?</h4>
            <h6>We'd love to hear your feedback!</h6>
          </div>
          <textarea
            rows={7}
            cols={10}
            className="feedbackInput"
            placeholder="Share your thoughts..."
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
          ></textarea>
        </div>
        <button className={`primaryButton feedbackButton ${feedbackText.length == 0 ? "invalid" : ""}`} onClick={handleFeedbackSubmit}>SUBMIT</button>
      </div>
    </div>
  );
}

import { themeColours } from "../resources";
import { FaCheck } from "react-icons/fa6";
import "./Checkbox.css"

const Checkbox = ({
  onClick,
  active,
  label,
}: {
  onClick: () => void;
  active: boolean;
  label: string;
}) => {
  return (
    <span className="customCheckbox">
      <div className="customBox" onClick={onClick}>
        {active && <FaCheck color={themeColours.primary} size={12} />}
      </div>
      <p>{label}</p>
    </span>
  );
};

export default Checkbox
import createRefresh from 'react-auth-kit/createRefresh';
import createStore from 'react-auth-kit/createStore';
import api from './api';

//Session refresh logic
const refresh = createRefresh({
  interval: 10,
  refreshApiCallback: async (param) => {
    try {
      const time = new Date()
      console.log("Calling refresh API...", time.toTimeString()); // Debugging
      const response = await api.post('/account/refresh', param)
      console.log("REFRESH RES", response)
      return {
        isSuccess: true,
        newAuthToken: response.data.accessToken,
        newAuthTokenExpireIn: 10,
        newRefreshTokenExpiresIn: 120,
      };
    } catch (error) {
      console.error(error);
      return {
        isSuccess: false,
        newAuthToken: '',
        newAuthTokenExpireIn: 10,
      };
    }
  },
});

export const store = createStore({
  authName: '_auth',
  authType: 'cookie',
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === 'https:',
  refresh: refresh,
});

import { Overlay2 } from "@blueprintjs/core";
import { ReactElement, useState } from "react";
import "./EditNameOverlay.css";

export function ConfirmationOverlay({
  text,
  onYes,
  toastMsg,
  element,
}: {
  text: string;
  onYes: () => void;
  toastMsg: string;
  element: ReactElement;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosed, setIsClosed] = useState(true);

  function handleOpenClick() {
    setIsOpen(true);
    setIsClosed(false);
  }

  function handleCloseClick() {
    setIsOpen(false);
    setIsClosed(true);
  }

  function handleYes() {
    onYes();
    handleCloseClick()
  }

  return (
    <>
      <span onClick={handleOpenClick} className="editNameSpan">
        {element}
      </span>
      {isOpen && <Overlay2 isOpen={isOpen} className={isClosed ? "" : "modalOverlay"} hasBackdrop={true}>
        <div className="modalContainer">
          <div className="modalCompleteContent" style={{ gap: "16px" }}>
            <h1 className="editNameHeading">{text}</h1>
            <span className="buttonSpan">
              <button className="secondaryButton" onClick={handleCloseClick}>
                No
              </button>
              <button className="primaryButton" onClick={handleYes}>
                Yes
              </button>
            </span>
          </div>
        </div>
      </Overlay2>}
    </>
  );
}

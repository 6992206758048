import { SelectionState } from "../../types";
import "./TemplateCard.css";
import TooltipMenu from "./EditTooltip";
import { useReducer, useState } from "react";

export function TemplateCard({
  name,
  config,
  createdAt,
  id,
  handleTemplateDelete,
}: {
  name: string;
  config: SelectionState;
  createdAt: string;
  id: string;
  handleTemplateDelete: (templateId: string) => void;
}) {
  const [templateName, setTemplateName] = useState(name);
  const specificItems = [];
  const categoryItems = [];
  for (const itemId in config) {
    if (config[itemId].barcode) specificItems.push(config[itemId]);
    else categoryItems.push(config[itemId]);
  }
  const hasSpecific = specificItems.length > 0;
  const hasCategory = categoryItems.length > 0;
  return (
    <div className="templateCardContainer">
      <span className="templateTitle">
        <h4>{templateName}</h4>
        <TooltipMenu
          name={name}
          templateId={id}
          handleTemplateDelete={handleTemplateDelete}
          setTemplateName={setTemplateName}
        />
      </span>
      <h5>
        {new Date(createdAt).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })}
      </h5>
      <div className="templateItems">
        {hasSpecific && (
          <>
            <span className="templateItemHeadSpecific">Specific Items</span>
            {specificItems.map((item, i) => (
              <p className="templateItemSpecific">
                {i + 1}. {item.title}
              </p>
            ))}
          </>
        )}
        {hasSpecific && hasCategory && <hr className="templateRule"></hr>}
        {hasCategory && (
          <>
            <span className="templateItemHeadSpecific">Categorized Items</span>
            <div className="templateItem templateItemHead">
              <p>Category</p>
              <p>Sub-category</p>
              <p>Brand</p>
            </div>
            {categoryItems.map((item, i) => {
              const subCat = Object.keys(item.subCategories)[0].replace("blank", "N/A");
              const subCatLen = Object.keys(subCat).length;
              const brand = Object.keys(item.brands)[0];
              const brandLen = Object.keys(item.brands).length;
              return (
                <div className="templateItem">
                  <p>
                    {i + 1}. {item.category}
                  </p>
                  <p>{subCatLen > 1 ? `${subCatLen} selected` : subCat}</p>
                  <p>{brandLen > 1 ? `${brandLen} selected` : brand}</p>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

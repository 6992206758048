import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";

export function Profile() {
  const signOut = useSignOut();
  const navigate = useNavigate();
  function handleSignOut() {
    signOut();
    return navigate("/login");
  }
  return (
    <div className="pageContainer">
      <button onClick={handleSignOut}>Sign out</button>
    </div>
  );
}

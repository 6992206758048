import { useCartContext } from "../../hooks";
import "./Basket.css";
import { BasketOverlay } from "./BasketOverlay";
import { storeListIcon, themeColours } from "../../resources";
import { Badge } from "@chakra-ui/react";
import { Icon } from "@blueprintjs/core";
import { useMemo, useState } from "react";
import { filterTitle } from "../../components/utils";
import { CartProductInfo } from "../../types";
import { getSavingsString } from "../../utils";
import { LuShoppingBasket } from "react-icons/lu";
import { BasketCompleteOverlay } from "./BasketCompleteOverlay";
import { useSelectionContext } from "../../hooks";
import { initialSelectionState } from "../../resources";
import { useNavigate } from "react-router-dom";

export function Basket() {
  const { cartState, setCartState } = useCartContext();
  const { setSelectionState } = useSelectionContext();
  const [showSavings, setShowSavings] = useState<boolean>(false);
  const navigate = useNavigate();

  const price = useMemo(() => {
    let price = 0;
    for (const productId in cartState) {
      price += cartState[productId].price * cartState[productId].count * cartState[productId].multipleCount;
    }
    return price;
  }, [cartState]);

  const { savings, valueSelected } = useMemo(() => {
    let savings = 0;
    let valueSelected = 0;
    for (const product of Object.values(cartState)) {
      if (product.isValueChoice) valueSelected++;
      savings += product.productSavings * product.count;
    }
    return { savings, valueSelected };
  }, [cartState]);

  const sortedProductIds = useMemo(() => {
    const ids = Object.values(cartState).sort((a: CartProductInfo, b: CartProductInfo) => {
      const aSavings = a.productSavings * a.count;
      const bSavings = b.productSavings * b.count;
      return bSavings - aSavings;
    });
    return ids;
  }, [cartState]);

  const isEmpty = Object.keys(cartState).length === 0;
  const isShopping = localStorage.getItem("isShop") === "T";

  function handleClearCart() {
    setCartState({});
    setSelectionState(initialSelectionState);
    localStorage.setItem("isShop", "F");
    return navigate("/");
  }

  if (isEmpty)
    return (
      <div className="pageContainer">
        <div className="cartContainer">
          <div className="emptyContent">
            <LuShoppingBasket color={themeColours.tertiary} size={25} />
            <text>Your cart is empty</text>
          </div>
          {isShopping && (
            <span className="emptyCartButtonSpan" style={{ justifyContent: "center" }}>
              <button className="secondaryButton cartCancelButton" onClick={handleClearCart}>
                Cancel Shop
              </button>
            </span>
          )}
        </div>
      </div>
    );

  const productIds = Object.keys(cartState);
  const isPositive = savings > 0;

  return (
    <div className="pageContainer">
      <div className="cartContainer">
        <div className="cartHeader">
          <div className="cartHeaderRow">
            <span className="cartHeaderSpan">
              <text className="cartHeaderText">Items</text>
              <Badge fontSize={"16px"} borderRadius={"16px"}>
                {Object.keys(cartState).length}
              </Badge>
            </span>
            <span className="cartHeaderSpan">
              <text className="cartHeaderText">Total </text>
              <Badge fontSize={"16px"} borderRadius={"16px"}>
                R{price.toFixed(2)}
              </Badge>
            </span>
          </div>
        </div>
        <div className="cartList">
          {Object.values(cartState).map((product, index) => {
            const { price, count, multipleCount, store, title, imageUrl } = product;
            const productPrice = (Number(price * multipleCount) * count).toFixed(2);
            return (
              <div className="cartRow">
                <div className="cartItem">
                  <text className="cartItemCount">{index + 1}. </text>
                  <img src={storeListIcon[store]} alt={title} width={10} />
                  <img src={imageUrl} alt={title} width={25} />
                  <text className="cartItemCount">{count}</text>
                  <text className="cartItemText">{filterTitle(product)}</text>
                </div>
                <div className="cartEdit">
                  <text className="cartItemPrice">R{productPrice}</text>
                  {<BasketOverlay product={product} />}
                </div>
              </div>
            );
          })}
        </div>
        <div className="cartSavings">
          <div className="cartSavingsContainer">
            <span className="cartSavingsSpanDropdown" style={{ boxShadow: "none" }}>
              <span className="cartSavingsTextPrice">
                <text className="cartSavingsText">Savings</text>
                <Badge
                  colorScheme={isPositive ? "green" : "red"}
                  fontSize={"16px"}
                  variant="subtle"
                  borderRadius={"16px"}
                >
                  R{savings.toFixed(2)}
                </Badge>
              </span>
              <Icon
                icon={showSavings ? "chevron-down" : "chevron-right"}
                color={themeColours.tertiary}
                onClick={() => setShowSavings((prev) => !prev)}
              />
            </span>
            {showSavings && (
              <div className="cartSavingsDropdown">
                <div className="cartSavingsDropdownLeft">
                  {sortedProductIds.map((product) => {
                    const index = productIds.indexOf(product.multipleId);
                    return (
                      <text className="cartSavingsListText">
                        {index + 1}. {product.category}: {product.subCategory}
                      </text>
                    );
                  })}
                </div>
                <div className="cartSavingsDropdownRight">
                  {sortedProductIds.map((product) => {
                    const { productSavings } = product;
                    const isGreen = productSavings >= 0;
                    const savingsString = getSavingsString(product);
                    return (
                      <text
                        className="cartSavingsListText"
                        style={{ color: isGreen ? themeColours.green : themeColours.red }}
                      >
                        {savingsString}
                      </text>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <span className="cartSavingsSpan">
            <text className="cartSavingsText">Value items selected</text>
            <Badge colorScheme="blue" fontSize={"16px"} variant="subtle" borderRadius={"16px"}>
              {valueSelected}
            </Badge>
            /
            <Badge colorScheme="blue" fontSize={"16px"} variant="subtle" borderRadius={"16px"}>
              {Object.keys(cartState).length}
            </Badge>
          </span>
        </div>
        <span className="cartButtonSpan">
          <button className="secondaryButton cartClear" onClick={handleClearCart}>
            Cancel
          </button>
          <BasketCompleteOverlay handleClearCart={handleClearCart} totalSavings={savings} />
        </span>
      </div>
    </div>
  );
}

export type ProductInfo = {
  id: string,
  title: string,
  price: number,
  salePrice: number,
  barcode: string,
  brand: string,
  store: string,
  volume: number,
  unit: string,
  promotionId: string | null,
  promotionCount: number,
  promotionPrice: number,
  promotionType: string
  imageUrl: string,
  category: string,
  subCategory: string,
  productGroup: string,
  selfAssigned: boolean,
  categoryUnit: string,
  categoryVolume: number,
  createdAt: Date,
  updatedAt: Date,
  isFlagged: boolean
  isVitality: boolean
};

export type MultipleProductInfo = ProductInfo & {multipleId: string, multipleCount: number}

export type CartProductInfo = MultipleProductInfo & {
  count: number;
  productSavings: number;
  valueSavings: number;
  isValueChoice: boolean
}

export const enum Stores {
  WOOLWORTHS = 'woolworths',
  CHECKERS = 'checkers',
  CLICKS = 'clicks',
  DISCHEM = 'Dis-chem'
}

export const enum Promotions {
  MULTIPLE = 'multiple',
  COMBO = 'combo',
  DISCOUNT = 'discount'
}

export enum JobType {
  PRODUCT_MAPPING = "product-mapping",
  NAME_SEARCH = "name-search",
  CATEGORY_SEARCH = "category-search",
  CATEGORY_RANK = "category_rank",
  FLAG_PRODUCT = 'flag_product',
  TEMPLATE_SHOP = 'template_shopt'
}

type StoreState = {
  [key: string]: true;
};

export type SearchState = {
  barcode: string | null;
  category: string;
  subCategories: {[key: string]: true};
  minVolume: number | null;
  maxVolume: number | null;
  brands: {[key: string]: true};
  stores: StoreState;
};

export type RankingDetails = {
  bestValueProductId: string,
  bestValueProductAmount: number,
  averageValueAmount: number
}


export type SelectionState = { [key: string]: SearchState & {title: string} };

export type CartContextState = {
  cartState: { [key: string]: CartProductInfo };
  setCartState: React.Dispatch<
    React.SetStateAction<{ [key: string]: ProductInfo }>
  >;
};
export type SelectionContextState = {
  selectionState: SelectionState;
  setSelectionState: React.Dispatch<React.SetStateAction<SelectionState>>;
};

export type MultiSelectorProps = {
  placeholder: string;
  listItems: string[];
  selectedValues: {[key: string]: true};
  handleSelectionChange: (update: any) => void;
  handleSelectionClear: () => void
}

export type Template = {
  id: string;
  name: string;
  config: SelectionState;
  createdAt: string
}

export type Shop = {
  id: string;
  date: string;
  items: {id: string, multipleCount: string, count: string, totalSavings: string}[];
  itemsTotal: number;
  savingsTotal: number
}

export type TemplateReturn = {
  [key: string]: {
    cartItem: CartProductInfo;
    searchState: SearchState
  }
}
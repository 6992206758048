import { useState } from "react";
import { JobType, MultipleProductInfo, Promotions, RankingDetails, SearchState, SelectionState } from "../../types";
import "./ProductCard.css";
import { Icon } from "@blueprintjs/core";
import { Badge, IconButton } from "@chakra-ui/react";
import { useCartContext, useSelectionContext } from "../../hooks";
import { imageWidths, initialSelectionState, storeIcon, themeColours } from "../../resources";
import {
  convertVolumeUnit,
  filterTitle,
  getProductPrice,
  getProductVolume,
  roundNonZero,
} from "../../components/utils";
import { BellIcon } from "@chakra-ui/icons";
import { LuSearch } from "react-icons/lu";
import { MEAT_CATEGORIES, VALUE_UNITS } from "../../resources";
import { caclulateSavings } from "../../utils";
import api from "../../api";
const vitalityPng = require("../../assets/vitality.png");

export function ProductCard({
  product,
  searchState,
  rankingDetails,
  setSearchState,
  fetchProducts,
}: {
  product: MultipleProductInfo;
  searchState: SearchState;
  rankingDetails: RankingDetails;
  setSearchState: (obj: SearchState) => void;
  fetchProducts: (barcode: string) => void;
}) {
  const [count, setCount] = useState(1);
  const { cartState, setCartState } = useCartContext();
  const { selectionState, setSelectionState } = useSelectionContext();
  const [isProductFlagged, setIsProductFlagged] = useState(product.isFlagged);
  const { bestValueProductId, averageValueAmount } = rankingDetails;
  const { volume } = convertVolumeUnit(product);
  const {
    categoryVolume,
    category,
    subCategory,
    multipleCount,
    price,
    categoryUnit,
    multipleId,
    store,
    title,
    id,
    brand,
    barcode,
    promotionCount,
    promotionPrice,
    imageUrl,
    isVitality,
  } = product;

  function handleAddProduct() {
    setCartState((prev) => ({
      ...prev,
      [multipleId]: {
        ...product,
        count,
        valueSavings: valueSavings,
        productSavings: productSavings,
        isValueChoice: isValueProduct,
      },
    }));
    setSelectionState((prev: SelectionState) => ({
      ...prev,
      [multipleId]: { ...searchState, title: product.title },
    }));
  }

  function handleRemoveProduct() {
    const newCartState = { ...cartState };
    const newSelectionState = { ...selectionState };
    delete newCartState[multipleId];
    delete newSelectionState[multipleId];
    setCartState(newCartState);
    setSelectionState(newSelectionState);
  }

  async function handleFlagProduct() {
    setIsProductFlagged(true);
    const payload = {
      jobType: JobType.FLAG_PRODUCT,
      params: {
        id,
      },
    };
    await api.post("/job/initiate", payload);
    return;
  }

  function handleProductSearch() {
    const search = { ...initialSelectionState.current, barcode };
    setSearchState(search);
    fetchProducts(barcode);
  }

  const productPrice = getProductPrice(product, count);
  let productValue = roundNonZero(productPrice / (categoryVolume * count * multipleCount));
  let valueString = `(R${productValue.toFixed(2)}/${categoryUnit})`;
  if (VALUE_UNITS.includes(categoryUnit)) {
    productValue = (productPrice * 100) / (categoryVolume * count * multipleCount);
    valueString = `(R${productValue.toFixed(2)}/100${categoryUnit})`;
  }
  if (MEAT_CATEGORIES.includes(category)) {
    productValue = productPrice / (volume * count * multipleCount);
    valueString = `(R${productValue.toFixed(2)}/kg)`;
  }

  const isValueProduct = bestValueProductId === multipleId;
  const productValueDiff =
    ((productPrice / (categoryVolume * count * multipleCount) - averageValueAmount) * 100) / averageValueAmount;
  const productValueSign = productValueDiff > 0 ? "+" : "-";
  const productValueString =
    productValueDiff !== 0 ? `${productValueSign}${Math.abs(productValueDiff).toFixed(0)}%` : "";
  const unitPrice = multipleCount === promotionCount ? promotionPrice : price;
  const { valueSavings, productSavings } = caclulateSavings(
    unitPrice / categoryVolume,
    averageValueAmount,
    categoryVolume,
    multipleCount
  );

  const cardTitle = filterTitle(product).split(" x")[0];
  const cardVolume = getProductVolume(product);
  const isMultiplePromotion = product.promotionType === Promotions.MULTIPLE;
  const isDiscountPromotion = product.promotionType === Promotions.DISCOUNT;
  const isPromotionValid = (product.multipleCount * count) % product.promotionCount === 0;
  const promotionText = `${product.promotionCount} for R${(product.promotionCount * product.promotionPrice).toFixed(
    2
  )}`;

  return (
    <div className="cardContainer">
      {cartState[multipleId] && <div className="cardOverlay"></div>}
      <div className="cardContainerTop">
        <span className="cardContainerStoreChips">
          <img src={storeIcon[store]} width={50} alt={"check"} />
          <span className="cardChips">
            {isMultiplePromotion && (
              <Badge
                colorScheme={isPromotionValid ? "red" : "gray"}
                paddingLeft={"8px"}
                paddingRight={"8px"}
                borderRadius={"16px"}
                alignContent={"center"}
                paddingBottom={"2px"}
              >
                <text className="cardPriceChip">{promotionText}</text>
              </Badge>
            )}
            {isDiscountPromotion && (
              <Badge
                colorScheme={"red"}
                paddingLeft={"8px"}
                paddingRight={"8px"}
                borderRadius={"16px"}
                alignContent={"center"}
                paddingBottom={"2px"}
              >
                <text className="cardPriceChip">{"SALE"}</text>
              </Badge>
            )}
            {isValueProduct && (
              <>
                <Badge
                  colorScheme="blue"
                  paddingLeft={"8px"}
                  paddingRight={"8px"}
                  borderRadius={"16px"}
                  alignContent={"center"}
                  paddingBottom={"2px"}
                >
                  <text className="cardPriceChip">Value</text>
                </Badge>
              </>
            )}
            <IconButton
              onClick={handleFlagProduct}
              variant="outline"
              aria-label="flag"
              size="xs"
              icon={<BellIcon color={"inherit"} />}
              colorScheme={isProductFlagged ? "red" : "gray"}
              color={isProductFlagged ? themeColours.red : themeColours.tertiary}
            />
            {barcode && (
              <IconButton
                onClick={handleProductSearch}
                variant="outline"
                aria-label="flag"
                size="xs"
                icon={<LuSearch color={"inherit"} />}
                colorScheme={"gray"}
                color={themeColours.tertiary}
              />
            )}
          </span>
        </span>
        <text className="cardTitle">
          {cardTitle} <text className="cardTitleHighlight">{multipleCount > 1 ? `[X${multipleCount}]` : ""}</text>
        </text>
        <div className="cardContainerMiddle">
          <div className="cardContainerLeft">
            <text className="cardBrand">{brand}</text>
            <text className="cardVolume">{cardVolume}</text>
            <span className="cardValue">
              <text className="cardVolume">{valueString}</text>
              <text
                className="cardVolume"
                style={{ color: productValueDiff < 0 ? themeColours.green : themeColours.red }}
              >
                {productValueString}
              </text>
            </span>
            <text className="cardPrice" style={{ color: isPromotionValid ? themeColours.red : "inherit" }}>
              <Icon icon="tag" color={isPromotionValid ? themeColours.red : themeColours.primary} size={10} />R
              {productPrice.toFixed(2)}
            </text>
          </div>
          <div className="cardContainerRight">
            {isVitality && <img src={vitalityPng} className="vitalityImage"></img>}
            <img src={imageUrl} alt={title} width={imageWidths[store]} />
          </div>
        </div>
      </div>
      <div className="cardContainerBottom">
        <span className="cardCountContainer">
          <span className="unitIcon">
            {" "}
            <Icon icon="minus" color="white" size={14} onClick={() => setCount(count - 1 <= 1 ? 1 : count - 1)} />
          </span>
          <div className="cardUnitCount">{count}</div>
          <span className="unitIcon">
            <Icon icon="plus" color="white" size={14} onClick={() => setCount(count + 1)} />
          </span>
        </span>
        {cartState[multipleId] ? (
          <button className="primaryButton productCardRemoveButton" onClick={handleRemoveProduct}>
            Remove
          </button>
        ) : (
          <button className="primaryButton productCardAddButton" onClick={handleAddProduct}>
            Add
          </button>
        )}
      </div>
    </div>
  );
}

import { Template } from "../../types";
import "./Template.css";
import { useEffect, useState } from "react";
import { themeColours } from "../../resources";
import { TemplateCard } from "./TemplateCard";
import { LuShoppingBasket } from "react-icons/lu";
import api from "../../api";

export function Templates() {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function handleTemplateDelete(templateId: string) {
    setTemplates((templates) => templates.filter((temp) => temp.id != templateId));
  }

  useEffect(() => {
    const fetchTemplates = async function () {
      try {
        const res = await api.post("/account/template/get");
        setTemplates(res.data.templates);
        setIsLoading(false);
      } catch (e) {
        console.error("Error fetching templates");
      }
    };
    setIsLoading(true);
    fetchTemplates();
  }, []);

  if (isLoading) {
    return (
      <div className="pageContainer">
        <div className="spinnerContainer">
          <div className="spinner"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="pageContainer">
      <div className="templateContainer">
        <h1>My templates</h1>
        {templates.length > 0 ? (
          templates.map((template) => <TemplateCard {...template} handleTemplateDelete={handleTemplateDelete} />)
        ) : (
          <div className="emptyTemplatePage">
            <LuShoppingBasket color={themeColours.tertiary} size={25} />
            <text>Your templates are empty</text>
          </div>
        )}
      </div>
    </div>
  );
}
